import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"


import Layout from "../components/layout"
import SEO from "../components/seo"

//import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
//import '../styles/SuperResponsiveTableStyle.css'

const pageTitle = "Projects"

const SecondPage = ({ pageContext, location }) => {
  const data = useStaticQuery(graphql`
    query MyQuery2 {
      image1: file(relativePath: { eq: "artemis-stnleo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "artemis-deployd.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "artemis-hablower1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "artemis-hablower2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5: file(relativePath: { eq: "artemis-hablower3.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const {
    breadcrumb: { crumbs },
  } = pageContext
  
  return (
    <Layout pageInfo={{ pageName: "projects" }} crumbs={crumbs} location={location} crumbLabel={pageTitle}>
      <SEO title={pageTitle} />

      <Container className="mx-4">
        <h1 className="page-heading">Projects</h1>

        <Container>
        <Row>
          <Col className="col-md-8">
            <p>
            Our goal is to establish an enduring private sector desination that opens the path for you to visit the Moon, at a price you would expect to pay for the luxury-class European capital tour. We first designed a mission to bring about this settlement in 1994. </p>

            <p>The name of the mission is The Artemis Project.
            </p>

            <p><Link to="/news/1995/01/artemis-project-selling-the-moon"><Img fluid={data.image1.childImageSharp.fluid} alt="" /></Link></p>
            <p><i>Artemis Mission 1 leaving Earth</i></p><br />

            <p><Link to="/news/1995/01/artemis-project-selling-the-moon"><Img fluid={data.image3.childImageSharp.fluid} alt="" /></Link></p>
            <p><i>Artemis Mission 1 habitat being lowered</i></p><br />

            <p><Link to="/news/1995/01/artemis-project-selling-the-moon"><Img fluid={data.image4.childImageSharp.fluid} alt="" /></Link></p>
            <p><i>Artemis Mission 1 habitat being lowered</i></p><br />

            <p><Link to="/news/1995/01/artemis-project-selling-the-moon"><Img fluid={data.image5.childImageSharp.fluid} alt="" /></Link></p>
            <p><i>Artemis Mission 1 habitat being lowered</i></p><br />

            <p><Link to="/news/1995/01/artemis-project-selling-the-moon"><Img fluid={data.image2.childImageSharp.fluid} alt="" /></Link></p>
            <p><i>Artemis Mission 1 landed at the settlement location</i></p>


          </Col>
          <Col className="col-md-4">
            <p>
              <h2>Resources:</h2>

              <p><a href="http://www.asi.org/adb/">Artemis Data Book</a></p>

              <p><a href="http://www.asi.org/adb/01/brief-overview.html">Artemis Project Overview</a></p>

              <p><a href="http://www.moonsociety.org/projects">Moon Society Projects</a></p>

            </p>
          </Col>
        </Row>
      </Container>


      </Container>
    </Layout>
  )
}

export default SecondPage
